import React, { useState, useEffect, Suspense, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Select from "react-select";

import { equipmentsPortfolioActions } from "../../store/equipmentsPortfolioSlice/equipmentsPortfolioSlice";

import "../../css/DailyReport.css";
import DailyReportsAPI from "../../api/DailyReportsApi";
import TableDataParser from "../../parser/DailyReport";
import BootstrapTable from "react-bootstrap-table-next";
import SingleDatePickerSimple from "../DatePickers/SingleDatePickerSimple/SingleDatePickerSimple";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";

import HarmonicMappingAPI from "../../api/HarmonicMappingApi";

import HarmonicAnalysisApi from "../../api/HarmonicAnalysisApi";
import TrendingAnalysisAPI from "../../api/TrendingAnalysisApi";
import EventAnalysisAPI from "../../api/EventsAnalysisApi";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

const HarmonicAmplitudeTrendingPlot = lazy(() => import("../plots/HarmonicAmplitudeTrendingPlot"));
const MinutesTrendingPlot15 = lazy(() => import("../plots/15MinutesTrendingPlot"));
const EventsTrendingPlot = lazy(() => import("../plots/EventsTrendingPlot"));
const EventsThresholdPlot = lazy(() => import("../plots/EventsThresholdPlot"));
const EventsCapturePlot = lazy(() => import("../plots/EventsCapturePlot"));

const { SearchBar, ClearSearchButton } = Search;

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

const reportOptions = [
    { label: "Voltage HAT Report V2", value: "voltage_hat_report_v2" },
    { label: "Current HAT Report V2", value: "current_hat_report_v2" },
    { label: "Trending Report", value: "trending_report" },
    { label: "Events Report", value: "events_report" },
    { label: "Harmonics Mapping Report", value: "harmonic_mapping_report" },
    { label: "Deployment Report", value: "deployment_report" },
    { label: "Alert Mapping Report", value: "alert_mapping_report" },
];

const noPlottingReport = ["harmonic_mapping_report", "portfolio_report", "alert_mapping_report", "deployment_report"];

export default function DailyReportsTab() {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { portfolio, selectedFacility, selectedEquipment, equipmentTimezone } = useSelector(
        (state) => state.equipmentsPortfolio
    );
    const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);
    const dispatch = useDispatch();

    const [reportDate, setReportDate] = useState(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 1));
    const [selectedReport, setSelectedReport] = useState({ label: "", value: "" });
    const [selectedRow, setSelectedRow] = useState();

    const [reportTables, setReportTables] = useState();

    const [reportTable, setReportTable] = useState([{ columns: "", rows: "" }]);
    const [reportTable1, setReportTable1] = useState([{ columns: "", rows: "" }]);
    const [reportTable2, setReportTable2] = useState([{ columns: "", rows: "" }]);
    const [reportTable3, setReportTable3] = useState([{ columns: "", rows: "" }]);
    const [reportTable4, setReportTable4] = useState([{ columns: "", rows: "" }]);
    const [reportTable5, setReportTable5] = useState([{ columns: "", rows: "" }]);
    const [reportTable6, setReportTable6] = useState([{ columns: "", rows: "" }]);
    const [reportTable7, setReportTable7] = useState([{ columns: "", rows: "" }]);
    const [reportTable8, setReportTable8] = useState([{ columns: "", rows: "" }]);
    const [reportTable9, setReportTable9] = useState([{ columns: "", rows: "" }]);

    const [showVoltageHarmonicPlot, setShowVoltageHarmonicPlot] = useState(false);
    const [isVoltageHarmonicPlotLoading, setIsVoltageHarmonicPlotLoading] = useState(false);
    const [isCurrentHarmonicPlotLoading, setIsCurrentHarmonicPlotLoading] = useState(false);

    const [showCurrentHarmonicPlot, setShowCurrentHarmonicPlot] = useState(false);

    const [voltageHarmonicPlotData, setVoltageHarmonicPlotData] = useState();
    const [currentHarmonicPlotData, setCurrentHarmonicPlotData] = useState();

    const [trendingReportData, setTrendingReportData] = useState({
        plotData: {},
        plotMetaData: "",
        showPlot: false,
        isPlotLoading: false,
    });

    const [isPlotLoading, setIsPlotLoading] = useState(false);
    const [plotButtonDisable, setPlotButtonDisable] = useState(true);

    useEffect(() => {
        setReportTable([{ columns: "", rows: "" }]);
        setReportTable1([{ columns: "", rows: "" }]);
        setReportTable2([{ columns: "", rows: "" }]);
        setReportTable3([{ columns: "", rows: "" }]);
        setReportTable4([{ columns: "", rows: "" }]);
        setReportTable5([{ columns: "", rows: "" }]);
        setReportTable6([{ columns: "", rows: "" }]);
        setReportTable7([{ columns: "", rows: "" }]);
        setReportTable8([{ columns: "", rows: "" }]);
        setReportTable9([{ columns: "", rows: "" }]);

        if (noPlottingReport.includes(selectedReport.value)) {
            setPlotButtonDisable(true);
        } else {
            setPlotButtonDisable(false);
        }
    }, [selectedReport]);

    const [plotOrder, setPlotOrder] = useState({
        voltageHAT: 1,
        currentHAT: 2,
        trending: 3,
        eventsTrending: 3,
        eventsThreshold: 4,
        eventsTimeline: 5,
        eventCapture: 6,
    });

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: "#9bcaff",
    };

    const [voltageHarmonicPlotMetaData, setVoltageHarmonicPlotMetaData] = useState({
        equipmentName: "",
        facilityName: "",
        harmonicType: "",
        harmonicLF: "",
        searchType: "",
        selectedParameters: [],
    });
    const [currentHarmonicPlotMetaData, setCurrentHarmonicPlotMetaData] = useState({
        equipmentName: "",
        facilityName: "",
        harmonicType: "",
        harmonicLF: "",
        searchType: "",
        selectedParameters: [],
    });

    const [eventsData, setEventsData] = useState({
        plotData: [],
        plotMetaData: [],
        showPlot: false,
        isEventsCapturePlotLoadingOnDailyReport: false,
    });

    const [eventsTrendingData, setEventsTrendingData] = useState({
        plotData: [],
        plotMetaData: [],
        showPlot: false,
        isEventsTrendingPlotLoadingOnDailyReport: false,
    });

    const [eventsThresholdData, setEventsThresholdData] = useState({
        plotData: [],
        plotMetaData: [],
        showPlot: false,
        isEventsThresholdPlotLoadingOnDailyReport: false,
    });

    useEffect(() => {
        if (eventsData.showPlot) {
            setEventsData((prevState) => {
                return {
                    ...prevState,
                    plotMetaData: { ...prevState.plotMetaData, selectedVoltageType: selectedVoltageType },
                };
            });
        }
        if (showVoltageHarmonicPlot || trendingReportData.showPlot) {
            onPlotButtonClick();
        }
    }, [selectedVoltageType]);

    const onReportSelect = (report) => {
        setSelectedReport(report);
        setPlotButtonDisable(true);

        // Clear the old report
        setReportTable([{ columns: "", rows: "" }]);
        setReportTable1([{ columns: "", rows: "" }]);
        setReportTable2([{ columns: "", rows: "" }]);
        setReportTable3([{ columns: "", rows: "" }]);

        if (report.value === "harmonic_mapping_report") {
            HarmonicMappingAPI.getHarmonicMapping(token)
                .then((response) => {
                    if (response.status === 204) {
                        console.log("no data"); // todo: add alert here on UI
                    } else if (response.status === 200) {
                        setReportTable(TableDataParser.harmonicMappingTable(response.data.content));
                        setReportTable1([{ columns: "", rows: "" }]);
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                    }
                })
                .catch((error) => console.log(error));
        } else {
            DailyReportsAPI.getDailyReport(token, report.value, formatDate(reportDate)).then((response) => {
                if (response.status === 200) {
                    if (report.value === "voltage_hat_report") {
                        setReportTable(TableDataParser.voltageHATReport(response.data.content.report_content));
                        setReportTable1([{ columns: "", rows: "" }]);
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                    } else if (report.value === "voltage_hat_report_v2") {
                        setReportTable(TableDataParser.voltageHATReportV2(response.data.content.report_content));
                        setReportTable1(
                            TableDataParser.voltageHATReportV2AbvThreshold(response.data.content.report_content)
                        );
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                    } else if (report.value === "current_hat_report_v2") {
                        setReportTable(TableDataParser.currentHATReportV2(response.data.content.report_content));

                        setReportTable1(
                            TableDataParser.currentHATReportV2AbvThreshold(response.data.content.report_content)
                        );
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                    } else if (report.value === "current_hat_report") {
                        setReportTable(TableDataParser.currentHATReport(response.data.content.report_content));

                        setReportTable1([{ columns: "", rows: "" }]);
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                    } else if (report.value === "trending_report") {
                        setReportTable(
                            TableDataParser.trendingReport(response.data.content.report_content.trending_report)
                        );

                        setReportTable1([{ columns: "", rows: "" }]);
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                    } else if (report.value === "events_report") {
                        setReportTable(
                            TableDataParser.eventsTrendingTable(response.data.content.report_content.events_trending)
                        );
                        setReportTable1(
                            TableDataParser.outliarEventsTable(response.data.content.report_content.outlier_events)
                        );
                        setReportTable2(
                            TableDataParser.noStartTable(response.data.content.report_content.no_start_events)
                        );
                        setReportTable3(
                            TableDataParser.noStopTable(response.data.content.report_content.no_stop_events)
                        );

                        if (response.data.content.report_content.no_voltage_transient_events.length) {
                            setReportTable4(
                                TableDataParser.noVoltageTransientsTable(
                                    response.data.content.report_content.no_voltage_transient_events
                                )
                            );
                            setReportTable5(
                                TableDataParser.noCurrentTransientsTable(
                                    response.data.content.report_content.no_current_transient_events
                                )
                            );
                        } else if (response.data.content.report_content.no_voltage_rise_events.length) {
                            setReportTable4(
                                TableDataParser.noVoltageRisesTable(
                                    response.data.content.report_content.no_voltage_rise_events
                                )
                            );
                            setReportTable5(
                                TableDataParser.noVoltageFallsTable(
                                    response.data.content.report_content.no_voltage_fall_events
                                )
                            );
                            setReportTable6(
                                TableDataParser.noCurrentRisesTable(
                                    response.data.content.report_content.no_current_rise_events
                                )
                            );
                            setReportTable7(
                                TableDataParser.noCurrentFallsTable(
                                    response.data.content.report_content.no_current_fall_events
                                )
                            );
                        }
                    } else if (report.value == "deployment_report") {
                        setReportTable(
                            TableDataParser.newItemsTables(
                                response.data.content.report_content.new_items_report,
                                response.data.content.report_content.previous_report_date
                            )
                        );
                        setReportTable1(
                            TableDataParser.resolvedItemsTable(
                                response.data.content.report_content.resolved_items_report,
                                response.data.content.report_content.previous_report_date
                            )
                        );
                        setReportTable2(
                            TableDataParser.missingChannelTable(
                                response.data.content.report_content.missing_channel_report
                            )
                        );
                        setReportTable3(
                            TableDataParser.connectionTable(response.data.content.report_content.connection_report)
                        );
                        setReportTable4(
                            TableDataParser.deploymentStatusTable(
                                response.data.content.report_content.deployment_status_report
                            )
                        );
                        setReportTable5(
                            TableDataParser.channelMappingTable(
                                response.data.content.report_content.channel_mapping_report
                            )
                        );
                        setReportTable6(
                            TableDataParser.voltageImbalanceReport(
                                response.data.content.report_content.voltage_imbalance_report
                            )
                        );
                        setReportTable7(
                            TableDataParser.notCenteredOnZeroTable(
                                response.data.content.report_content.not_centered_zero_report
                            )
                        );
                        setReportTable8(
                            TableDataParser.deploymentIssueReport(response.data.content.report_content.portfolio_report)
                        );
                        setReportTable9(
                            TableDataParser.lateEventsTable(response.data.content.report_content.late_event_report)
                        );
                    } else if (report.value == "alert_mapping_report") {
                        setReportTable(TableDataParser.alertMappingReport(response.data.content.report_content));
                        setReportTable1([{ columns: "", rows: "" }]);
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                    }
                }
            });
        }
    };

    const onReportDateChange = (date) => {
        setReportDate(date);
        setPlotButtonDisable(true);

        // Clear the old report
        setReportTable([{ columns: "", rows: "" }]);
        setReportTable1([{ columns: "", rows: "" }]);
        setReportTable2([{ columns: "", rows: "" }]);
        setReportTable3([{ columns: "", rows: "" }]);
        setReportTable4([{ columns: "", rows: "" }]);
        setReportTable5([{ columns: "", rows: "" }]);
        setReportTable6([{ columns: "", rows: "" }]);
        setReportTable7([{ columns: "", rows: "" }]);
        setReportTable8([{ columns: "", rows: "" }]);
        setReportTable9([{ columns: "", rows: "" }]);

        if (selectedReport.label) {
            DailyReportsAPI.getDailyReport(token, selectedReport.value, formatDate(date)).then((response) => {
                if (response.status === 200) {
                    if (selectedReport.value === "voltage_hat_report") {
                        setReportTable(TableDataParser.voltageHATReport(response.data.content.report_content));
                        setReportTable1([{ columns: "", rows: "" }]);
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                        setReportTable4([{ columns: "", rows: "" }]);
                        setReportTable5([{ columns: "", rows: "" }]);
                    } else if (selectedReport.value === "voltage_hat_report_v2") {
                        setReportTable(TableDataParser.voltageHATReportV2(response.data.content.report_content));

                        setReportTable1(
                            TableDataParser.voltageHATReportV2AbvThreshold(response.data.content.report_content)
                        );
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                    } else if (selectedReport.value === "current_hat_report_v2") {
                        setReportTable(TableDataParser.currentHATReportV2(response.data.content.report_content));

                        setReportTable1(
                            TableDataParser.currentHATReportV2AbvThreshold(response.data.content.report_content)
                        );
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                    } else if (selectedReport.value === "current_hat_report") {
                        setReportTable(TableDataParser.currentHATReport(response.data.content.report_content));

                        setReportTable1([{ columns: "", rows: "" }]);
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                        setReportTable4([{ columns: "", rows: "" }]);
                        setReportTable5([{ columns: "", rows: "" }]);
                    } else if (selectedReport.value === "trending_report") {
                        setReportTable(
                            TableDataParser.trendingReport(response.data.content.report_content.trending_report)
                        );

                        setReportTable1([{ columns: "", rows: "" }]);
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                        setReportTable4([{ columns: "", rows: "" }]);
                        setReportTable5([{ columns: "", rows: "" }]);
                    } else if (selectedReport.value === "events_report") {
                        setReportTable(
                            TableDataParser.eventsTrendingTable(response.data.content.report_content.events_trending)
                        );
                        setReportTable1(
                            TableDataParser.outliarEventsTable(response.data.content.report_content.outlier_events)
                        );
                        setReportTable2(
                            TableDataParser.noStartTable(response.data.content.report_content.no_start_events)
                        );
                        setReportTable3(
                            TableDataParser.noStopTable(response.data.content.report_content.no_stop_events)
                        );

                        if (response.data.content.report_content.no_voltage_transient_events.length) {
                            setReportTable4(
                                TableDataParser.noVoltageTransientsTable(
                                    response.data.content.report_content.no_voltage_transient_events
                                )
                            );
                            setReportTable5(
                                TableDataParser.noCurrentTransientsTable(
                                    response.data.content.report_content.no_current_transient_events
                                )
                            );
                        } else if (response.data.content.report_content.no_voltage_rise_events.length) {
                            setReportTable4(
                                TableDataParser.noVoltageRisesTable(
                                    response.data.content.report_content.no_voltage_rise_events
                                )
                            );
                            setReportTable5(
                                TableDataParser.noVoltageFallsTable(
                                    response.data.content.report_content.no_voltage_fall_events
                                )
                            );
                        }
                    } else if (selectedReport.value === "voltage_imbalance_report") {
                        setReportTable(
                            TableDataParser.voltageImbalanceReport(
                                response.data.content.report_content.voltage_imbalance_report
                            )
                        );
                        setReportTable1([{ columns: "", rows: "" }]);
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                    } else if (selectedReport.value == "deployment_report") {
                        setReportTable(
                            TableDataParser.newItemsTables(
                                response.data.content.report_content.new_items_report,
                                response.data.content.report_content.previous_report_date
                            )
                        );
                        setReportTable1(
                            TableDataParser.resolvedItemsTable(
                                response.data.content.report_content.resolved_items_report,
                                response.data.content.report_content.previous_report_date
                            )
                        );
                        setReportTable2(
                            TableDataParser.missingChannelTable(
                                response.data.content.report_content.missing_channel_report
                            )
                        );
                        setReportTable3(
                            TableDataParser.connectionTable(response.data.content.report_content.connection_report)
                        );
                        setReportTable4(
                            TableDataParser.deploymentStatusTable(
                                response.data.content.report_content.deployment_status_report
                            )
                        );
                        setReportTable5(
                            TableDataParser.channelMappingTable(
                                response.data.content.report_content.channel_mapping_report
                            )
                        );
                        setReportTable6(
                            TableDataParser.voltageImbalanceReport(
                                response.data.content.report_content.voltage_imbalance_report
                            )
                        );
                        setReportTable7(
                            TableDataParser.notCenteredOnZeroTable(
                                response.data.content.report_content.not_centered_zero_report
                            )
                        );
                        setReportTable8(
                            TableDataParser.deploymentIssueReport(response.data.content.report_content.portfolio_report)
                        );
                        setReportTable9(
                            TableDataParser.lateEventsTable(response.data.content.report_content.late_event_report)
                        );
                    } else if (selectedReport.value == "alert_mapping_report") {
                        setReportTable(TableDataParser.alertMappingReport(response.data.content.report_content));
                        setReportTable1([{ columns: "", rows: "" }]);
                        setReportTable2([{ columns: "", rows: "" }]);
                        setReportTable3([{ columns: "", rows: "" }]);
                    }
                }
            });
        }
    };

    const onRowSelect = {
        onClick: (e, row, rowIndex) => {
            if (!noPlottingReport.includes(selectedReport.value)) {
                // Set equipment Start date
                let equipment = portfolio.equipments.find((value) => {
                    return value.node_sn === row.node_sn;
                });

                dispatch(equipmentsPortfolioActions.setSelectedEquipment(equipment));
                row.equipment = equipment;
                setSelectedRow(row);
                setPlotButtonDisable(false);
            }
        },
        onDoubleClick: () => {
            if (!noPlottingReport.includes(selectedReport.value)) {
                onPlotButtonClick();
            }
        },
    };

    const handlePlotType = (typeofPlot) => {
        if (typeofPlot === "voltage_harmonics") {
            setIsVoltageHarmonicPlotLoading(true);
            setVoltageHarmonicPlotData({
                plot_data: {
                    harmonics_phase_a: { x: [], y: [], name: "" },
                    harmonics_phase_b: { x: [], y: [], name: "" },
                    harmonics_phase_c: { x: [], y: [], name: "" },
                    line_frequency: { x: [], y: [], name: "" },
                    short_term_scan_results: { x: [], y: [], name: "" },
                    long_term_scan_results: { x: [], y: [], name: "" },
                },
                search_harmonic: 0,
                subplot_data: {},
            });
            setShowVoltageHarmonicPlot(true);
            setPlotOrder({
                voltageHAT: 1,
                currentHAT: 2,
                trending: 3,
                eventsTrending: 4,
                eventsTimeline: 5,
                eventsThreshold: 6,
                eventCapture: 7,
            });
        } else if (typeofPlot === "current_harmonics") {
            setIsCurrentHarmonicPlotLoading(true);
            setCurrentHarmonicPlotData({
                plot_data: {
                    harmonics_phase_a: { x: [], y: [], name: "" },
                    harmonics_phase_b: { x: [], y: [], name: "" },
                    harmonics_phase_c: { x: [], y: [], name: "" },
                    short_term_scan_results: { x: [], y: [], name: "" },
                    long_term_scan_results: { x: [], y: [], name: "" },
                },
                search_harmonic: 0,
                subplot_data: {},
            });
            setShowCurrentHarmonicPlot(true);
            setPlotOrder({
                voltageHAT: 2,
                currentHAT: 1,
                trending: 3,
                eventsTrending: 4,
                eventsTimeline: 5,
                eventsThreshold: 6,
                eventCapture: 7,
            });
        }
    };

    const onPlotButtonClick = () => {
        setIsPlotLoading(true);
        handlePlotType(selectedRow.plot_type);

        if (selectedRow.plot_type === "voltage_harmonics" || selectedRow.plot_type === "current_harmonics") {
            setVoltageHarmonicPlotMetaData({
                selectedEquipment: selectedEquipment,
                equipmentName: selectedEquipment.label,
                facilityName: selectedFacility.label,
                harmonicType: selectedRow.harmonic_type,
                harmonicLF: selectedRow.harmonic_lf,
                searchType: "LF",
                selectedVoltageType: selectedVoltageType,
                selectedParameters: [],
                selectedNodes: [selectedEquipment],
            });

            setCurrentHarmonicPlotMetaData({
                selectedEquipment: selectedEquipment,
                equipmentName: selectedEquipment.label,
                facilityName: selectedFacility.label,
                harmonicType: selectedRow.harmonic_type,
                harmonicLF: selectedRow.harmonic_lf,
                searchType: "LF",
                selectedVoltageType: selectedVoltageType,
                selectedParameters: [],
                selectedNodes: [selectedEquipment],
            });
            if (selectedEquipment.product_type === "Node") {
                HarmonicAnalysisApi.get_harmonic_amp_trending_plot(
                    token,
                    selectedEquipment.value,
                    formatDate(new Date(new Date().setMonth(reportDate.getMonth() - 3))),
                    formatDate(reportDate),
                    selectedRow.harmonic_lf,
                    selectedRow.harmonic_type,
                    "6 hour",
                    "LF",
                    selectedEquipment.vfd_driven,
                    equipmentTimezone.value,
                    selectedVoltageType.value,
                    "garbage tolerance to fill the space",
                    true
                )
                    .then((response) => {
                        if (response.status === 200) {
                            if (selectedRow.plot_type === "voltage_harmonics") {
                                setVoltageHarmonicPlotData(response.data.content);
                                setIsPlotLoading(false);
                                setIsVoltageHarmonicPlotLoading(false);
                                setShowVoltageHarmonicPlot(true);
                            } else if (selectedRow.plot_type === "current_harmonics") {
                                setIsCurrentHarmonicPlotLoading(true);
                                setCurrentHarmonicPlotData(response.data.content);
                                setIsCurrentHarmonicPlotLoading(false);
                                setShowCurrentHarmonicPlot(true);
                                setIsPlotLoading(false);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsPlotLoading(false);
                    });
            } else {
                HarmonicAnalysisApi.get_sel_harmonic_plot(
                    token,
                    selectedEquipment.value,
                    formatDate(new Date(new Date().setMonth(reportDate.getMonth() - 3))),
                    formatDate(reportDate),
                    selectedRow.harmonic_lf,
                    selectedRow.harmonic_type,
                    "6 hour",
                    "LF",
                    equipmentTimezone.value,
                    "garbage tolerance to fill the space"
                )
                    .then((response) => {
                        if (response.status === 200) {
                            if (selectedRow.plot_type === "voltage_harmonics") {
                                setVoltageHarmonicPlotData(response.data.content);
                                setIsPlotLoading(false);
                                setIsVoltageHarmonicPlotLoading(false);
                                setShowVoltageHarmonicPlot(true);
                            } else if (selectedRow.plot_type === "current_harmonics") {
                                setIsCurrentHarmonicPlotLoading(true);
                                setCurrentHarmonicPlotData(response.data.content);
                                setIsCurrentHarmonicPlotLoading(false);
                                setShowCurrentHarmonicPlot(true);
                                setIsPlotLoading(false);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsPlotLoading(false);
                    });
            }
        } else if (selectedRow.plot_type === "trending") {
            setTrendingReportData((prevData) => {
                return {
                    ...prevData,
                    plotData: {
                        data: [],
                        layout: {},
                    },
                    tableData: {
                        columns: [{ dataField: "", text: "" }],
                        data: [{ "": "", Voltage: 0, Current: 0, LineFrequency: 0 }],
                    },
                    plotMetaData: {
                        selectedEquipment: selectedEquipment,
                        v_type: selectedVoltageType.value,
                        selectedParameters: selectedRow.plot_params,
                        startDate: formatDate(new Date(new Date().setMonth(reportDate.getMonth() - 3))),
                        endDate: formatDate(reportDate),
                        timezone: equipmentTimezone.value,
                    },
                    isPlotOnDailyReportsTabLoading: true,
                    showPlot: true,
                };
            });

            TrendingAnalysisAPI.getFifteenMinuteTrend(
                token,
                selectedEquipment.value,
                equipmentTimezone.value,
                formatDate(new Date(new Date().setMonth(reportDate.getMonth() - 3))),
                formatDate(reportDate),
                selectedRow.plot_params,
                selectedVoltageType.value,
                selectedEquipment.work_cycle
            )
                .then((response) => {
                    if (response.status === 204) {
                        console.log(204);
                    } else {
                        setIsPlotLoading(false);
                        setPlotOrder({
                            voltageHAT: 3,
                            currentHAT: 2,
                            trending: 1,
                            eventsTrending: 4,
                            eventsTimeline: 5,
                            eventsThreshold: 6,
                            eventCapture: 7,
                        });
                        setTrendingReportData((prevData) => {
                            return {
                                ...prevData,
                                plotData: response.data.content,
                                showPlot: true,
                                isPlotOnDailyReportsTabLoading: false,
                            };
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsPlotLoading(false);
                    setTrendingReportData((prevData) => {
                        return {
                            ...prevData,
                            isPlotOnDailyReportsTabLoading: false,
                        };
                    });
                });
        } else if (selectedRow.plot_type === "events_count") {
            setEventsTrendingData((prevState) => {
                return {
                    ...prevState,
                    plotMetaData: {
                        selectedEquipment: selectedEquipment,
                        startDate: formatDate(new Date(new Date().setMonth(reportDate.getMonth() - 3))),
                        endDate: formatDate(reportDate),
                    },
                };
            });

            setEventsThresholdData((prevState) => {
                return {
                    ...prevState,
                    plotMetaData: {
                        selectedEquipment: selectedEquipment,
                        startDate: formatDate(new Date(new Date().setMonth(reportDate.getMonth() - 3))),
                        endDate: formatDate(reportDate),
                    },
                };
            });

            setEventsTrendingData((prevState) => {
                return {
                    ...prevState,
                    plotData: {
                        current_fall: [],
                        current_rise: [],
                        day: [],
                        start: [],
                        stop: [],
                        voltage_fall: [],
                        voltage_rise: [],
                    },
                    isEventsTrendingPlotLoadingOnDailyReport: true,
                    showPlot: true,
                };
            });

            EventAnalysisAPI.get_events_trending_data(
                token,
                selectedEquipment.value,
                formatDate(new Date(new Date().setMonth(reportDate.getMonth() - 3))),
                formatDate(reportDate),
                equipmentTimezone.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setIsPlotLoading(false);
                    } else {
                        setEventsTrendingData((prevState) => {
                            return {
                                ...prevState,
                                plotData: response.data.content.plot_data.events_trending,
                                isEventsTrendingPlotLoadingOnDailyReport: false,
                                showPlot: true,
                            };
                        });
                        setPlotOrder({
                            voltageHAT: 6,
                            currentHAT: 5,
                            trending: 4,
                            eventsTrending: 1,
                            eventsTimeline: 2,
                            eventsThreshold: 3,
                            eventCapture: 7,
                        });
                    }
                })
                .catch((err) => {
                    setIsPlotLoading(false);
                    console.log(err);
                });

            setEventsThresholdData((prevState) => {
                return {
                    ...prevState,
                    plotData: {
                        pq_delta_i: [],
                        pq_delta_v: [],
                        pqd_imax: [],
                        pqd_imin: [],
                        pqd_vmax: [],
                        pqd_vmin: [],
                        time: [],
                    },
                    isEventsThresholdPlotLoadingOnDailyReport: true,
                    showPlot: true,
                };
            });

            EventAnalysisAPI.get_event_threshold_plot_data(
                token,
                selectedEquipment.value,
                formatDate(new Date(new Date().setMonth(reportDate.getMonth() - 3))),
                formatDate(reportDate),
                equipmentTimezone.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setIsPlotLoading(false);
                    } else {
                        setEventsThresholdData((prevState) => {
                            return {
                                ...prevState,
                                plotData: response.data.content.plot_data,
                                isEventsThresholdPlotLoadingOnDailyReport: false,
                                showPlot: true,
                            };
                        });
                        setIsPlotLoading(false);
                    }
                })
                .catch((err) => {
                    setIsPlotLoading(false);
                    console.log(err);
                });
        } else if (selectedRow.plot_type === "outlier_event") {
            setPlotOrder({
                voltageHAT: 7,
                currentHAT: 6,
                trending: 5,
                eventsTrending: 2,
                eventsTimeline: 3,
                eventsThreshold: 4,
                eventCapture: 1,
            });

            setEventsData((prevState) => {
                return {
                    ...prevState,
                    plotData: {
                        current: { Ia: [], Ib: [], Ic: [] },
                        voltage_ln: { Va: [], Vb: [], Vc: [] },
                        time: [],
                    },
                    plotMetaData: {
                        selectedEquipment: selectedEquipment,
                        selectedESA: {
                            label:
                                selectedRow.event_utc_timestamp.toString().slice(0, -2) +
                                " UTC - " +
                                selectedRow.event_type,
                            value: selectedRow.s3_bucket_key_pair,
                        },
                        analysisType: "None",
                        selectedVoltageType: selectedVoltageType,
                    },
                    isEventsCapturePlotLoadingOnDailyReport: true,
                    showPlot: true,
                };
            });

            EventAnalysisAPI.event_capture_plot_data(
                token,
                selectedRow.s3_bucket_key_pair,
                selectedEquipment.location_node_id,
                "None",
                selectedRow.equipment.product_type
            )
                .then((response) => {
                    if (response.status === 204) {
                        setIsPlotLoading(false);
                    } else {
                        setEventsData((prevState) => {
                            return {
                                ...prevState,
                                plotData: response.data.content.plot_data,
                                showPlot: true,
                                isEventsCapturePlotLoadingOnDailyReport: false,
                            };
                        });

                        setIsPlotLoading(false);
                        setPlotOrder({
                            voltageHAT: 7,
                            currentHAT: 6,
                            trending: 5,
                            eventsTrending: 2,
                            eventsTimeline: 3,
                            eventsThreshold: 4,
                            eventCapture: 1,
                        });
                    }
                })
                .catch((err) => {
                    setIsPlotLoading(false);
                    console.log(err);
                });
        }
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <Row className="mt-3">
                        {/************************************ Facility and Node Selection Column **************************************************************/}
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col className="col-4">
                                            <label className="select_title">Select Report:</label>
                                            <Select
                                                className="Select-option"
                                                options={reportOptions}
                                                onChange={(value) => {
                                                    onReportSelect(value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="col-3">
                                            <label className="select_title">Select Date:</label>
                                            <br></br>
                                            <SingleDatePickerSimple
                                                startDate={reportDate}
                                                onDateChange={onReportDateChange}
                                            />
                                        </Col>
                                        <Col>
                                            {isPlotLoading ? (
                                                <ReactLoading type="bars" color="#007bff" height={50} width={50} />
                                            ) : (
                                                <Button
                                                    variant="primary"
                                                    onClick={onPlotButtonClick}
                                                    disabled={plotButtonDisable}
                                                    className="plot_button"
                                                >
                                                    Plot
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                    <br></br>

                                    {selectedReport.label ? (
                                        <Card className={`${selectedReport.value}`}>
                                            <Card.Header>
                                                <h4>
                                                    {selectedReport.label} {`for ${formatDate(reportDate)}`}
                                                </h4>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        data={
                                                            Array.isArray(reportTable1.rows)
                                                                ? [...reportTable.rows, ...reportTable1.rows]
                                                                : reportTable.rows
                                                        }
                                                        columns={reportTable.columns}
                                                        search
                                                        bootstrap4
                                                    >
                                                        {(props) => (
                                                            <Col>
                                                                <Row>
                                                                    <Col className="col-2">
                                                                        <SearchBar {...props.searchProps} />
                                                                    </Col>
                                                                    <Col className="col-1">
                                                                        <ClearSearchButton
                                                                            className="btn-primary btn_alert"
                                                                            {...props.searchProps}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    {reportTable.length !== 1 ? (
                                                                        <Col className="col-12 DailyReportTable_tableCSS">
                                                                            <label className="table_title">
                                                                                {reportTable.title}
                                                                            </label>
                                                                            <div className="tableDescription">
                                                                                {reportTable.description}
                                                                            </div>
                                                                            <div className="table-container">
                                                                                <>
                                                                                    <BootstrapTable
                                                                                        keyField="id"
                                                                                        {...props.baseProps}
                                                                                        data={reportTable.rows}
                                                                                        columns={reportTable.columns}
                                                                                        selectRow={selectRow}
                                                                                        rowEvents={onRowSelect}
                                                                                        className="fixed-table"
                                                                                    />
                                                                                </>
                                                                            </div>
                                                                        </Col>
                                                                    ) : null}
                                                                </Row>
                                                                <Row className="mt-4">
                                                                    {reportTable1.length !== 1 ? (
                                                                        <Col className="DailyReportTable2 col-10">
                                                                            <label className="table_title">
                                                                                {reportTable1.title}
                                                                            </label>
                                                                            <div className="tableDescription">
                                                                                {reportTable1.description}
                                                                            </div>
                                                                            <div className="table-container">
                                                                                <>
                                                                                    <BootstrapTable
                                                                                        keyField="id"
                                                                                        {...props.baseProps}
                                                                                        data={reportTable1.rows}
                                                                                        columns={reportTable1.columns}
                                                                                        selectRow={selectRow}
                                                                                        rowEvents={onRowSelect}
                                                                                        className="fixed-table"
                                                                                    />
                                                                                </>
                                                                            </div>
                                                                        </Col>
                                                                    ) : null}
                                                                </Row>
                                                            </Col>
                                                        )}
                                                    </ToolkitProvider>

                                                    <Row className="mt-4">
                                                        {reportTable2.length !== 1 ? (
                                                            <Col className="DailyReportTable3 col-10">
                                                                <label className="table_title">
                                                                    {reportTable2.title}
                                                                </label>
                                                                <div className="tableDescription">
                                                                    {reportTable2.description}
                                                                </div>
                                                                <>
                                                                    <div className="table-container">
                                                                        <BootstrapTable
                                                                            keyField="id"
                                                                            data={reportTable2.rows}
                                                                            columns={reportTable2.columns}
                                                                            className="fixed-table"
                                                                        />
                                                                    </div>
                                                                </>
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                    <Row className="mt-4">
                                                        {reportTable3.length !== 1 ? (
                                                            <Col className="DailyReportTable4 col-10">
                                                                <label className="table_title">
                                                                    {reportTable3.title}
                                                                </label>
                                                                <div className="tableDescription">
                                                                    {reportTable3.description}
                                                                </div>
                                                                <>
                                                                    <div className="table-container">
                                                                        <BootstrapTable
                                                                            keyField="id"
                                                                            data={reportTable3.rows}
                                                                            columns={reportTable3.columns}
                                                                            className="fixed-table"
                                                                        />
                                                                    </div>
                                                                </>
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                    <Row className="mt-4">
                                                        {reportTable4.length !== 1 ? (
                                                            <Col className="DailyReportTable5 col-10">
                                                                <label className="table_title">
                                                                    {reportTable4.title}
                                                                </label>
                                                                <div className="tableDescription">
                                                                    {reportTable4.description}
                                                                </div>
                                                                <>
                                                                    <div className="table-container">
                                                                        <BootstrapTable
                                                                            keyField="id"
                                                                            data={reportTable4.rows}
                                                                            columns={reportTable4.columns}
                                                                            className="fixed-table"
                                                                        />
                                                                    </div>
                                                                </>
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                    <Row className="mt-4">
                                                        {reportTable5.length !== 1 ? (
                                                            <Col className="DailyReportTable6 col-10">
                                                                <label className="table_title">
                                                                    {reportTable5.title}
                                                                </label>
                                                                <div className="tableDescription">
                                                                    {reportTable5.description}
                                                                </div>
                                                                <>
                                                                    <div className="table-container">
                                                                        <BootstrapTable
                                                                            keyField="id"
                                                                            data={reportTable5.rows}
                                                                            columns={reportTable5.columns}
                                                                            className="fixed-table"
                                                                        />
                                                                    </div>
                                                                </>
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                    <Row className="mt-4">
                                                        {reportTable6.length !== 1 ? (
                                                            <Col className="DailyReportTable6 col-10">
                                                                <label className="table_title">
                                                                    {reportTable6.title}
                                                                </label>
                                                                <div className="tableDescription">
                                                                    {reportTable6.description}
                                                                </div>
                                                                <>
                                                                    <div className="table-container">
                                                                        <BootstrapTable
                                                                            keyField="id"
                                                                            data={reportTable6.rows}
                                                                            columns={reportTable6.columns}
                                                                            className="fixed-table"
                                                                        />
                                                                    </div>
                                                                </>
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                    <Row className="mt-4">
                                                        {reportTable7.length !== 1 ? (
                                                            <Col className="DailyReportTable6 col-10">
                                                                <label className="table_title">
                                                                    {reportTable7.title}
                                                                </label>
                                                                <div className="tableDescription">
                                                                    {reportTable7.description}
                                                                </div>
                                                                <>
                                                                    <div className="table-container">
                                                                        <BootstrapTable
                                                                            keyField="id"
                                                                            data={reportTable7.rows}
                                                                            columns={reportTable7.columns}
                                                                            className="fixed-table"
                                                                        />
                                                                    </div>
                                                                </>
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                    <Row className="mt-4">
                                                        {reportTable8.length !== 1 ? (
                                                            <Col className="DailyReportTable6 col-10">
                                                                <label className="table_title">
                                                                    {reportTable8.title}
                                                                </label>
                                                                <div className="tableDescription">
                                                                    {reportTable8.description}
                                                                </div>
                                                                <>
                                                                    <div className="table-container">
                                                                        <BootstrapTable
                                                                            keyField="id"
                                                                            data={reportTable8.rows}
                                                                            columns={reportTable8.columns}
                                                                            className="fixed-table"
                                                                        />
                                                                    </div>
                                                                </>
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                    <Row className="mt-4">
                                                        {reportTable9.length !== 1 ? (
                                                            <Col className="DailyReportTable6 col-10">
                                                                <label className="table_title">
                                                                    {reportTable9.title}
                                                                </label>
                                                                <div className="tableDescription">
                                                                    {reportTable9.description}
                                                                </div>
                                                                <>
                                                                    <div className="table-container">
                                                                        <BootstrapTable
                                                                            keyField="id"
                                                                            data={reportTable9.rows}
                                                                            columns={reportTable9.columns}
                                                                            className="fixed-table"
                                                                        />
                                                                    </div>
                                                                </>
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    ) : null}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br></br>
            {/********************************************** Plot Section ***************************************************************************/}

            <Suspense fallback={<div>Loading..</div>}>
                <Row>
                    {/* Voltage HAT Report PLot */}
                    <Col lg={{ order: plotOrder.voltageHAT, span: 12 }}>
                        {showVoltageHarmonicPlot ? (
                            <HarmonicAmplitudeTrendingPlot
                                harmonicPlotData={voltageHarmonicPlotData}
                                setHarmonicPlotData={setVoltageHarmonicPlotData}
                                setShowPlot={setShowVoltageHarmonicPlot}
                                showPlot={showVoltageHarmonicPlot}
                                plotMetaData={voltageHarmonicPlotMetaData}
                                isVoltagePlotLoading={isVoltageHarmonicPlotLoading}
                            />
                        ) : null}
                    </Col>

                    {/* Current HAT Report PLot */}
                    <Col lg={{ order: plotOrder.currentHAT, span: 12 }}>
                        {showCurrentHarmonicPlot ? (
                            <HarmonicAmplitudeTrendingPlot
                                harmonicPlotData={currentHarmonicPlotData}
                                setHarmonicPlotData={setCurrentHarmonicPlotData}
                                setShowPlot={setShowCurrentHarmonicPlot}
                                showPlot={showCurrentHarmonicPlot}
                                plotMetaData={currentHarmonicPlotMetaData}
                                isCurrentPlotLoading={isCurrentHarmonicPlotLoading}
                            />
                        ) : null}
                    </Col>

                    {/* Trending Report PLot */}
                    <Col lg={{ order: plotOrder.trending, span: 12 }}>
                        {trendingReportData.showPlot ? (
                            <MinutesTrendingPlot15 tabData={trendingReportData} setTabData={setTrendingReportData} />
                        ) : null}
                    </Col>
                    <Col lg={{ order: plotOrder.eventsTrending, span: 12 }}>
                        {eventsTrendingData.showPlot ? (
                            <EventsTrendingPlot tabData={eventsTrendingData} setTabData={setEventsTrendingData} />
                        ) : null}
                    </Col>

                    <Col lg={{ order: plotOrder.eventsThreshold, span: 12 }}>
                        {eventsThresholdData.showPlot ? (
                            <EventsThresholdPlot tabData={eventsThresholdData} setTabData={setEventsThresholdData} />
                        ) : null}
                    </Col>

                    <Col lg={{ order: plotOrder.eventCapture, span: 12 }}>
                        {eventsData.showPlot ? (
                            <EventsCapturePlot tabData={eventsData} setTabData={setEventsData} />
                        ) : null}
                    </Col>
                </Row>
            </Suspense>
        </div>
    );
}
