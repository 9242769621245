import { FifteenMinuteParser } from "./TrendingAnalysis";

class FFTPlotParser {
    static getEmptyResponse() {
        const plotResponse = {
            V1: { x: [], y: [] },
            V2: { x: [], y: [] },
            V3: { x: [], y: [] },
            I1: { x: [], y: [] },
            I2: { x: [], y: [] },
            I3: { x: [], y: [] },
            plotTitle: "",
        };
        return plotResponse;
    }
    static getPlotResponse(plotMetaData) {
        const plotResponse = {
            plotTitle:
                plotMetaData.selectedEquipment.plot_title_node_label +
                ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
                "<br>Timestamp:" +
                plotMetaData.esa_timestamp,
        };
        return plotResponse;
    }

    static getPlotResponseLL(plotData, plotMetaData) {
        const plotDataResponse = {
            ...plotData,
            I1: {
                x: plotData.I1.x,
                y: plotData.I1.y,
            },
            I2: {
                x: plotData.I2.x,
                y: plotData.I2.y,
            },
            I3: {
                x: plotData.I3.x,
                y: plotData.I3.y,
            },
            legend_Va: "Vab",
            legend_Vb: "Vbc",
            legend_Vc: "Vca",

            legend_Ia: "Ia",
            legend_Ib: "Ib",
            legend_Ic: "Ic",

            plotTitle:
                plotMetaData.selectedEquipment.plot_title_node_label +
                ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
                "<br>Timestamp:" +
                plotMetaData.selectedFFTEsa.label,

            y1_title: "% of LF Magnitude",
            y2_title: "% of LF Magnitude",

            first_plot_heading: "Voltage FFT",
            second_plot_heading: "Current FFT",
            rotorBars: plotData.freqs?.rotor_bars,
            runningSpeed: plotData.freqs?.running_speed,
        };
        if (plotMetaData.fftDemodulateFlag == true && plotData.I1.y.constructor === Array) {
            const I1 = plotData.I1;
            const I2 = plotData.I2;
            const I3 = plotData.I3;
            const V1 = plotData.V1;
            const V2 = plotData.V2;
            const V3 = plotData.V3;

            const I1y = Array(I1.length);
            const I2y = Array(I2.length);
            const I3y = Array(I3.length);

            I1.y.map((val, i) => {
                if (Math.abs(V1.y[i]) > Math.abs(val)) {
                    I1y[i] = 0;
                } else {
                    I1y[i] = I1.y[i];
                }
            });
            I2.y.map((val, i) => {
                if (Math.abs(V2.y[i]) > Math.abs(val)) {
                    I2y[i] = 0;
                } else {
                    I2y[i] = I2.y[i];
                }
            });
            I3.y.map((val, i) => {
                if (Math.abs(V3.y[i]) > Math.abs(val)) {
                    I3y[i] = 0;
                } else {
                    I3y[i] = I3.y[i];
                }
            });
            plotDataResponse.I1.y = I1y;
            plotDataResponse.I2.y = I2y;
            plotDataResponse.I3.y = I3y;
        }

        if (plotMetaData.selectedEquipment.eq_type === "dc") {
            plotDataResponse.legend_Va = "V-DC";
            plotDataResponse.V2.x = [];
            plotDataResponse.V3.x = [];

            plotDataResponse.legend_Ia = "I-DC";
            plotDataResponse.I2.x = [];
            plotDataResponse.I3.x = [];
        } else if (plotMetaData.selectedEquipment.eq_type_sub == "v1") {
            plotDataResponse.legend_Va = "V";
            plotDataResponse.V2.x = [];
            plotDataResponse.V3.x = [];
        }
        return plotDataResponse;
    }

    static getPlotResponseLN(plotData, plotMetaData) {
        const plotDataResponse = {
            ...plotData,
            I1: {
                x: plotData.I1.x,
                y: plotData.I1.y,
            },
            I2: {
                x: plotData.I2.x,
                y: plotData.I2.y,
            },
            I3: {
                x: plotData.I3.x,
                y: plotData.I3.y,
            },
            legend_Va: "Va",
            legend_Vb: "Vb",
            legend_Vc: "Vc",

            legend_Ia: "Ia",
            legend_Ib: "Ib",
            legend_Ic: "Ic",

            plotTitle:
                plotMetaData.selectedEquipment.plot_title_node_label +
                ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
                "<br>Timestamp:" +
                plotMetaData.selectedFFTEsa.label,

            y1_title: "% of LF Magnitude",
            y2_title: "% of LF Magnitude",

            first_plot_heading: "Voltage FFT",
            second_plot_heading: "Current FFT",
            rotorBars: plotData.freqs?.rotor_bars,
            runningSpeed: plotData.freqs?.running_speed,
        };
        if (plotMetaData.fftDemodulateFlag == true && plotData.I1.y.constructor === Array) {
            const I1 = plotData.I1;
            const I2 = plotData.I2;
            const I3 = plotData.I3;
            const V1 = plotData.V1;
            const V2 = plotData.V2;
            const V3 = plotData.V3;

            const I1y = Array(I1.length);
            const I2y = Array(I2.length);
            const I3y = Array(I3.length);

            I1.y.map((val, i) => {
                if (Math.abs(V1.y[i]) > Math.abs(val)) {
                    I1y[i] = 0;
                } else {
                    I1y[i] = I1.y[i];
                }
            });
            I2.y.map((val, i) => {
                if (Math.abs(V2.y[i]) > Math.abs(val)) {
                    I2y[i] = 0;
                } else {
                    I2y[i] = I2.y[i];
                }
            });
            I3.y.map((val, i) => {
                if (Math.abs(V3.y[i]) > Math.abs(val)) {
                    I3y[i] = 0;
                } else {
                    I3y[i] = I3.y[i];
                }
            });
            plotDataResponse.I1.y = I1y;
            plotDataResponse.I2.y = I2y;
            plotDataResponse.I3.y = I3y;
        }
        if (plotMetaData.selectedEquipment.eq_type_sub == "i1") {
            plotDataResponse.legend_Va = "V";
            plotDataResponse.V2.x = [];
            plotDataResponse.V3.x = [];

            plotDataResponse.legend_Ia = "I";
            plotDataResponse.I2.x = [];
            plotDataResponse.I3.x = [];
        }
        return plotDataResponse;
    }

    static getComparisonResponse(plotData1, plotData2, plotMetaData) {
        if (!plotData1 || !plotData2) return this.getEmptyResponse();

        const eq1 = plotMetaData.fftFileOneEquipment;
        const eq2 = plotMetaData.fftFileTwoEquipment;

        const subtractArrays = (arr1, arr2) => {
            const method = "closest";

            const len1 = arr1.x.length;
            const len2 = arr2.x.length;
            let idx2 = 0;

            //Initialize the index fo array two to a point just lower than the x val at index 0 in array 1
            while (arr2.x[idx2] < arr1.x[0] && idx2 < len2) idx2 = idx2 + 1;
            if (idx2) idx2 -= 1;

            const retY = [];
            for (var i = 0; i < len1; i++) {
                if (idx2 == 0 && arr1[i] < arr2[0]) {
                    //Push array1 until array 2 starts
                    retY.push(arr1.y[i]);
                } else if (idx2 >= len2 || arr1.x[i] > arr2.x[len2 - 1]) {
                    //If the second array is done, append the remainder of array 1
                    retY.push(arr1.y.slice(i));
                    break;
                } else if (arr1.x[i] == arr2.x[idx2]) {
                    //If the x vals match, subtract across
                    retY.push(arr1.y[i] - arr2.y[idx2]);
                    idx2 = idx2 + 1;
                } else {
                    //Find the difference in Y values between the 2 arrays at the x coord
                    switch (method) {
                        case "closest":
                            //Find the closest x value in array 2 to the current x position in array1
                            while (
                                idx2 + 1 < len2 &&
                                Math.abs(arr1.x[i] - arr2.x[idx2 + 1]) < Math.abs(arr1.x[i] - arr2.x[idx2])
                            )
                                idx2 = idx2 + 1;
                            retY.push(arr1.y[i] - arr2.y[idx2]);
                    }
                }
            }
            return { x: arr1.x, y: retY };
        };

        const V1 = subtractArrays(plotData1.V1, plotData2.V1);
        const V2 = subtractArrays(plotData1.V2, plotData2.V2);
        const V3 = subtractArrays(plotData1.V3, plotData2.V3);
        const I1 = subtractArrays(plotData1.I1, plotData2.I1);
        const I2 = subtractArrays(plotData1.I2, plotData2.I2);
        const I3 = subtractArrays(plotData1.I3, plotData2.I3);

        if (plotMetaData.fftDemodulateFlag == true) {
            I1.y.map((val, i) => {
                if (Math.abs(V1.y[i]) > Math.abs(val)) {
                    I1.y[i] = 0;
                }
            });
            I2.y.map((val, i) => {
                if (Math.abs(V2.y[i]) > Math.abs(val)) {
                    I2.y[i] = 0;
                }
            });
            I3.y.map((val, i) => {
                if (Math.abs(V3.y[i]) > Math.abs(val)) {
                    I3.y[i] = 0;
                }
            });
        }

        const plotDataResponse = {
            V1: V1,
            V2: V2,
            V3: V3,
            I1: I1,
            I2: I2,
            I3: I3,

            legend_Ia: "Ia",
            legend_Ib: "Ib",
            legend_Ic: "Ic",

            plotTitle:
                eq1.plot_title_node_label +
                ` (${eq1.np_voltage}V, ${eq1.np_current}A, ${eq1.np_rpm}RPM, ${eq1.np_hp}HP)` +
                "<br>Timestamp:" +
                plotMetaData.fftFileOne.label +
                "<br><br>" +
                eq2.plot_title_node_label +
                ` (${eq2.np_voltage}V, ${eq2.np_current}A, ${eq2.np_rpm}RPM, ${eq2.np_hp}HP)` +
                "<br>Timestamp:" +
                plotMetaData.fftFileTwo.label,

            y1_title: "% of LF Magnitude",
            y2_title: "% of LF Magnitude",

            first_plot_heading: "Voltage FFT",
            second_plot_heading: "Current FFT",
            lf_type: plotData1.lf_type,
        };

        if (plotMetaData.selectedVoltageType.value === "L-L") {
            plotDataResponse["legend_Va"] = "Vab";
            plotDataResponse["legend_Vb"] = "Vbc";
            plotDataResponse["legend_Vc"] = "Vca";
        } else {
            plotDataResponse["legend_Va"] = "Va";
            plotDataResponse["legend_Vb"] = "Vb";
            plotDataResponse["legend_Vc"] = "Vc";
        }

        return plotDataResponse;
    }
    static getParamsTable(tableData, metaData) {
        let cols = tableData.columns;
        if (metaData.selectedEquipment.eq_type_sub === "v1") {
            const filterCols = ["Vbc", "Vca"];
            cols = cols.filter((col) => {
                if (col.text === "Vab") {
                    col.text = "V";
                }
                return !filterCols.includes(col.text);
            });
        } else if (metaData.selectedEquipment.eq_type_sub === "i1") {
            const filterCols = ["Vb", "Vc", "Ib", "Ic"];
            cols = cols.filter((col) => {
                if (col.text === "Va") {
                    col.text = "V";
                } else if (col.text === "Ia") {
                    col.text = "I";
                }
                return !filterCols.includes(col.text);
            });
        }
        return {
            keyField: "id",
            data: tableData.rows,
            columns: cols,
        };
    }
}

const estimateY = (time, phase) => {
    const timeArr = phase.x;
    const t = new Date(time);

    let lowerTimeIndex = 0;
    let upperTimeIndex = timeArr.length - 1;

    if (t < new Date(timeArr[lowerTimeIndex])) {
        upperTimeIndex = 0;
    } else if (t > new Date(timeArr[upperTimeIndex])) {
        lowerTimeIndex = upperTimeIndex;
    } else {
        while (lowerTimeIndex < upperTimeIndex) {
            const midIndex = Math.floor((lowerTimeIndex + upperTimeIndex) / 2);
            if (t < new Date(timeArr[midIndex])) {
                upperTimeIndex = midIndex;
            } else if (t > new Date(timeArr[midIndex])) {
                lowerTimeIndex = midIndex + 1;
            } else {
                lowerTimeIndex = midIndex;
                upperTimeIndex = midIndex;
            }
        }
        lowerTimeIndex = lowerTimeIndex - 1;
    }

    const slope =
        upperTimeIndex == lowerTimeIndex
            ? 0
            : (phase.y[upperTimeIndex] - phase.y[lowerTimeIndex]) /
              (new Date(phase.x[upperTimeIndex]).getTime() - new Date(phase.x[lowerTimeIndex]).getTime());
    const dx = new Date(time).getTime() - new Date(phase.x[lowerTimeIndex]).getTime();
    const y_guess = slope * dx + phase.y[lowerTimeIndex];

    return y_guess;
};

const formatText = (text, maxLength) => {
    const words = text.split(" ");
    const lines = [];
    let currentPart = "";

    for (const word of words) {
        if ((currentPart + " " + word).trim().length <= maxLength) {
            currentPart = (currentPart + " " + word).trim();
        } else {
            lines.push(currentPart);
            currentPart = word;
        }
    }
    if (currentPart !== "") {
        lines.push(currentPart);
    }

    return "&nbsp;<br>" + lines.join("<br>") + "<br>&nbsp;";
};

class HATPlotParser {
    static getEmptyResponse() {
        return { data: [], layout: {} };
    }

    static getPlotResponse(plotMetaData, plotData, lf_value) {
        if (plotMetaData.harmonicType === "Voltage") {
            if (plotMetaData.selectedVoltageType.value === "L-L") {
                if (plotMetaData.selectedEquipment.eq_type === "dc") {
                    plotData.legend_x11 = "V-DC";
                    plotData.legend_x12 = "";
                    plotData.legend_x13 = "";
                } else if (plotMetaData.selectedEquipment.eq_type_sub === "v1") {
                    plotData.legend_x11 = "V";
                    plotData.legend_x12 = "";
                    plotData.legend_x13 = "";
                } else {
                    plotData.legend_x11 = "Vab";
                    plotData.legend_x12 = "Vbc";
                    plotData.legend_x13 = "Vca";
                }
            } else {
                if (plotMetaData.selectedEquipment.eq_type_sub === "i1") {
                    plotData.legend_x11 = "V";
                    plotData.legend_x12 = "";
                    plotData.legend_x13 = "";
                } else {
                    plotData.legend_x11 = "Va";
                    plotData.legend_x12 = "Vb";
                    plotData.legend_x13 = "Vc";
                }
            }
        } else if (plotMetaData.harmonicType === "Demodulated") {
            plotData.legend_x11 = "Ia - Va";
            plotData.legend_x12 = "Ib - Vb";
            plotData.legend_x13 = "Ic - Vc";
        } else {
            if (plotMetaData.selectedEquipment.eq_type === "dc") {
                plotData.legend_x11 = "I-DC";
                plotData.legend_x12 = "";
                plotData.legend_x13 = "";
            } else if (plotMetaData.selectedEquipment.eq_type_sub === "i1") {
                plotData.legend_x11 = "I";
                plotData.legend_x12 = "";
                plotData.legend_x13 = "";
            } else {
                plotData.legend_x11 = "Ia";
                plotData.legend_x12 = "Ib";
                plotData.legend_x13 = "Ic";
            }
        }

        if (plotData.plot_data?.short_term_scan_results.y.length > 0) {
            plotData.legend_x14 = "Short Term Scan";
        } else {
            plotData.legend_x14 = "";
        }
        if (plotData.plot_data?.long_term_scan_results.y.length > 0) {
            plotData.legend_x15 = "Long Term Scan";
        } else {
            plotData.legend_x15 = "";
        }

        const title =
            plotMetaData.selectedEquipment.plot_title_node_label +
            ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
            "<br>Harmonic Amplitude Trending - " +
            plotMetaData.harmonicType +
            ": " +
            lf_value +
            " " +
            plotMetaData.searchType;
        const y1_title = "% of LF Magnitude";

        const rangeStart = new Date(plotMetaData.startDate);
        const rangeEnd = new Date(plotMetaData.endDate);

        rangeStart.setDate(rangeStart.getUTCDate());
        rangeStart.setHours(0, 0, 0);

        rangeEnd.setDate(rangeEnd.getUTCDate());
        rangeEnd.setHours(23, 59, 59);

        const layout = {
            title: {
                font: { size: 22 },
                x: 0.06,
                y: 0.95,
                align: "top",
                text: title,
            },
            xaxis: {
                title: {
                    font: { size: 22 },
                    x: 0.0,
                    text: "",
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
                range: [rangeStart, rangeEnd],
            },
            yaxis: {
                title: {
                    font: { size: 22 },
                    x: 0.0,
                    text: y1_title,
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            },
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            annotations: [],
            legend: { font: { size: 22 } },
        };

        //Create traces for the harmonic data
        const data = [
            {
                line: { color: "black" },
                mode: "lines",
                name: plotData.legend_x11,
                type: "scatter",
                x: plotData.plot_data.harmonics_phase_a.x,
                y: plotData.plot_data.harmonics_phase_a.y,
                yaxis: "y1",
                legendgroup: plotData.legend_x11,
                connectgaps: false,
            },
            {
                mode: "lines",
                name: plotData.legend_x11,
                type: "scatter",
                x: plotData.plot_data.harmonics_phase_a.x,
                y: plotData.plot_data.harmonics_phase_a.y,
                yaxis: "y1",
                connectgaps: true,
                line: {
                    color: "black",
                    dash: "2px,4px",
                    width: "2",
                },
                showlegend: false,
                legendgroup: plotData.legend_x11,
            },
            {
                line: { color: "red" },
                mode: "lines",
                name: plotData.legend_x12,
                type: "scatter",
                x: plotData.plot_data.harmonics_phase_b.x,
                y: plotData.plot_data.harmonics_phase_b.y,
                yaxis: "y1",
                legendgroup: plotData.legend_x12,
                connectgaps: false,
            },
            {
                mode: "lines",
                name: plotData.legend_x12,
                type: "scatter",
                x: plotData.plot_data.harmonics_phase_b.x,
                y: plotData.plot_data.harmonics_phase_b.y,
                yaxis: "y1",
                connectgaps: true,
                line: {
                    color: "red",
                    dash: "2px,4px",
                    width: "2",
                },
                showlegend: false,
                legendgroup: plotData.legend_x12,
            },
            {
                line: { color: "blue" },
                mode: "lines",
                name: plotData.legend_x13,
                type: "scatter",
                x: plotData.plot_data.harmonics_phase_c.x,
                y: plotData.plot_data.harmonics_phase_c.y,
                yaxis: "y1",
                legendgroup: plotData.legend_x13,
                connectgaps: false,
            },
            {
                mode: "lines",
                name: plotData.legend_x13,
                type: "scatter",
                x: plotData.plot_data.harmonics_phase_c.x,
                y: plotData.plot_data.harmonics_phase_c.y,
                yaxis: "y1",
                connectgaps: true,
                line: {
                    color: "blue",
                    dash: "2px,4px",
                    width: "2",
                },
                showlegend: false,
                legendgroup: plotData.legend_x13,
            },
            {
                marker: {
                    color: "orange",
                    size: 15,
                },
                mode: "markers",
                name: plotData.legend_x14,
                type: "scattergl",
                x: plotData.plot_data.short_term_scan_results.x,
                y: plotData.plot_data.short_term_scan_results.y,
                yaxis: "y1",
            },
            {
                marker: {
                    color: "purple",
                    size: 15,
                },
                mode: "markers",
                name: plotData.legend_x15,
                type: "scattergl",
                x: plotData.plot_data.long_term_scan_results.x,
                y: plotData.plot_data.long_term_scan_results.y,
                yaxis: "y1",
            },
        ];

        // Get subplot data
        plotMetaData["v_type"] = plotMetaData["selectedVoltageType"]["value"];
        const plot = FifteenMinuteParser.parseData(plotData.subplot_data, plotMetaData).plot;
        const subplots = plot.data;
        const trending_layout = plot.layout;
        // Move all axis down 1 index because the harmonic trend is the first
        subplots.forEach((trace, i, arr) => {
            const axis = parseInt(trace["yaxis"].slice(1)) + 1;
            trace["yaxis"] = `y${axis}`;
            trending_layout["annotations"][axis - 2]["yref"] = `y${axis} domain`;
            layout[`yaxis${axis}`] = trending_layout[`yaxis${axis - 1}`];
            layout["annotations"].push(trending_layout.annotations[axis - 2]);
            data.push(subplots[i]);
        });

        // Format plot height and subplot layouts
        const plotCount = data.reduce((max, trace) => Math.max(max, parseInt(trace["yaxis"].slice(1))), 0);
        const plotHeight = 600 + 100 * (plotCount - 1);

        const subplotCount = plotCount - 1;
        const gapSize = 1 / (4 * subplotCount + 9);
        const subplotDomainSize = 3 * gapSize;

        for (let i = 0; i < subplotCount; i++) {
            const domain1 = Math.round(i * (subplotDomainSize + gapSize) * 1e15) / 1e15;
            const domain2 = Math.round((domain1 + subplotDomainSize) * 1e15) / 1e15;
            const axis = subplotCount + 1 - i;
            layout[`yaxis${axis}`] = {
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
                domain: [domain1, domain2],
            };
        }
        const harmonicDomain1 = Math.round(subplotCount * (subplotDomainSize + gapSize) * 1e15) / 1e15;
        const harmonicDomain2 = Math.round((harmonicDomain1 + 3 * subplotDomainSize) * 1e15) / 1e15;
        layout["yaxis"]["domain"] = [harmonicDomain1, harmonicDomain2];

        // Add any notes to plot annotations
        const max_y_plot = Math.max(
            ...(plotData.plot_data?.harmonics_phase_a.y ?? []),
            ...(plotData.plot_data?.harmonics_phase_b.y ?? []),
            ...(plotData.plot_data?.harmonics_phase_c.y ?? [])
        );
        const notes = plotData.plot_data?.notes?.x.map((time, i) => {
            const y_guess_1 = estimateY(time, plotData.plot_data.harmonics_phase_a);
            const y_guess_2 = estimateY(time, plotData.plot_data.harmonics_phase_b);
            const y_guess_3 = estimateY(time, plotData.plot_data.harmonics_phase_c);

            const max_y_at_index = Math.max(y_guess_1, y_guess_2, y_guess_3);

            const text = formatText(plotData.plot_data.notes.text[i], 30);

            return {
                x: time,
                y: max_y_at_index,
                xref: "x",
                yref: "y",
                text: text,
                showarrow: true,
                arrowhead: 0,
                ayref: "y",
                ay: max_y_plot,
                yanchor: "bottom",
                yshift: 12,
                ax: 0,
            };
        });
        if (notes) {
            layout["annotations"].push(...notes);
        }

        layout["grid"] = { columns: 1, rows: plotCount };
        layout["height"] = plotHeight;

        return { data, layout };
    }
}

export { FFTPlotParser, HATPlotParser };
