import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Plot from "react-plotly.js";
import createDownloadCSV from "../../utility/exportCSV/exportCsv";
import { AccumulatedParser } from "../../parser/TrendingAnalysis";
import ReactLoading from "react-loading";
import "../../css/OneSecondNewTrendingPlot.css";

const AccumulatedTrendingPlot = ({ tabData, setTabData }) => {
    const [plotData, setPlotData] = useState({
        data: [],
        layout: {},
    });

    useEffect(() => {
        if (tabData.showPlot) {
            if (tabData.selectedTrend == "Amp Hours") {
                setPlotData(
                    AccumulatedParser.parseAmpOverSF(
                        tabData.content,
                        tabData.equipmentsList,
                        tabData.plotWithEstimationFlag,
                        tabData.plotMetaData
                    )
                );
            } else if (tabData.selectedTrend == "Starts") {
                setPlotData(
                    AccumulatedParser.parseStarts(
                        tabData.content,
                        tabData.equipmentsList,
                        tabData.plotWithEstimationFlag,
                        tabData.plotMetaData
                    )
                );
            }
        }
    }, [tabData]);

    useEffect(() => {
        const plotDataArea = document.querySelector(".plotGraphAccumulatedArea");
        if (tabData.isPlotLoading) plotDataArea.scrollIntoView({ block: "start", behavior: "smooth" });
    }, [tabData.isPlotLoading]);

    useEffect(() => {
        const plotDataArea = document.querySelector(".plotGraphAccumulatedArea");
        plotDataArea.scrollIntoView({ block: "start", behavior: "smooth" });
    }, []);

    const onPlotCloseButtonClick = () => {
        setTabData((prevData) => {
            return {
                ...prevData,
                showPlot: false,
            };
        });
    };

    const onDownloadCsvButtonClick = () => {
        const columns = ["Date"];
        for (const trace of plotData.data) {
            columns.push(trace.name);
        }

        const data = [columns.join(",") + "\n"];

        const traceCount = plotData.data.length;
        const traceDataIdx = new Array(traceCount).fill(0); //Stores the index of the next data point to record for each trace

        const isDataRemaining = () => {
            var dataRemaining = false;
            for (let i = 0; i < traceCount; i++) {
                if (traceDataIdx[i] < plotData.data[i].x.length) {
                    dataRemaining = true;
                    break;
                }
            }
            return dataRemaining;
        };

        while (isDataRemaining() == true) {
            const row = [];

            //Get the next date
            let minDate = null;
            for (const [traceIdx, trace] of Object.entries(plotData.data)) {
                const dataPoint = traceDataIdx[traceIdx];
                if (dataPoint >= trace.x.length) continue;

                if (minDate == null || trace.x[dataPoint] < minDate) {
                    minDate = trace.x[dataPoint];
                }
            }
            row.push(minDate);

            //Add data to the row with matching dates
            for (const [traceIdx, trace] of Object.entries(plotData.data)) {
                const dataPoint = traceDataIdx[traceIdx];
                if (dataPoint >= trace.x.length) continue;

                if (trace.x[dataPoint] == minDate) {
                    row.push(trace.y[dataPoint]);
                    traceDataIdx[traceIdx]++;
                } else {
                    row.push(null);
                }
            }
            data.push(row.join(",") + "\n");
        }
        const csvString = data.join("");
        const csvFileName = `${tabData.selectedFacility.label} Trending Analysis (Accumulated ${tabData.selectedTrend}).csv`;
        createDownloadCSV(csvString, csvFileName);
    };

    return (
        <Card className="plotGraphAccumulatedArea">
            {tabData.isPlotLoading ? (
                <row className="plot-loading-bars-One-Second-NewTrending-page">
                    <div>
                        <ReactLoading type="bars" color="#007bff" height={120} width={120} />
                    </div>
                </row>
            ) : null}
            <Card.Header>
                <Row>
                    <Col className="col-11">
                        <h3 className="display-inline">Trending Analysis Plot (Accumulated)</h3>
                        <Button className="export-csv-button" onClick={onDownloadCsvButtonClick}>
                            Export CSV
                        </Button>
                    </Col>
                    <Col className="co-1 align-right">
                        <Button variant="secondary" onClick={onPlotCloseButtonClick} className="x_button">
                            X
                        </Button>
                    </Col>
                </Row>
            </Card.Header>
            <Row>
                <Col>
                    <Plot
                        data={plotData.data}
                        layout={plotData.layout}
                        config={{
                            displaylogo: false,
                            modeBarButtonsToRemove: [
                                "zoom2d",
                                "pan2d",
                                "select2d",
                                "lasso2d",
                                "zoomIn2d",
                                "zoomOut2d",
                                "autoScale2d",
                                "toggleSpikelines",
                                "hoverClosestCartesian",
                                "hoverCompareCartesian",
                            ],
                        }}
                        useResizeHandler
                        className="plotCSS"
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default React.memo(AccumulatedTrendingPlot);
